<template>
  <div
    style="
      background: rgba(255, 255, 255, 0.6);
      position: sticky;
      top: 0px;
      height: 85vh;
    "
  >
    <v-row
      no-gutters
      style="font-size: 18px; padding: 0; margin: 0; height: 100%"
    >
      <v-col class="notification-tabs-bar" cols="12" sm="4">
        <div style="padding: 20px">
          <v-list-item-group color="#008fc7" v-model="selectedItem">
            <!-- Bar Notifikasi -->
            <v-list-item
              :value="0"
              style="margin-bottom: 20px; border-radius: 20px"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px">
                  <v-icon style="margin-right: 5%">mdi-bell</v-icon>
                  <strong>Notifikasi</strong>
                </v-list-item-title>
              </v-list-item-content>
              <div
                style="font-size: 12px; margin-right: 20px"
                v-if="getNotification.totalUnread > 0"
              >
                <v-badge
                  color="red"
                  small
                  :content="getNotification.totalUnread"
                >
                </v-badge>
              </div>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-subheader>BUTUH PERSETUJUAN ANDA</v-subheader>
            <!-- Bar Pengajuan Izin -->
            <v-list-item
              :value="1"
              style="border-radius: 20px; overflow: hidden"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px">
                  <v-icon style="margin-right: 5%">mdi-file-document</v-icon>
                  <strong>Pengajuan Izin</strong>
                </v-list-item-title>
              </v-list-item-content>
              <div
                style="font-size: 12px; margin-right: 20px"
                v-if="getPermissionApprovalNeeds.result.length > 0"
              >
                <v-badge
                  color="red"
                  small
                  :content="getPermissionApprovalNeeds.totalData"
                >
                </v-badge>
              </div>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <!-- Bar Pengajuan Cuti -->
            <v-list-item
              :value="2"
              style="border-radius: 20px; overflow: hidden"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px">
                  <v-icon style="margin-right: 5%">mdi-calendar-plus</v-icon>
                  <strong>Pengajuan Cuti</strong>
                </v-list-item-title>
              </v-list-item-content>
              <div
                style="font-size: 12px; margin-right: 20px"
                v-if="getLeaveApprovalNeeds.result.length > 0"
              >
                <v-badge
                  color="red"
                  small
                  :content="getLeaveApprovalNeeds.totalData"
                >
                </v-badge>
              </div>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <!-- Bar Pengajuan FPB -->
            <v-list-item
              :value="3"
              style="border-radius: 20px; overflow: hidden"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px">
                  <v-icon style="margin-right: 5%">mdi-clipboard-list-outline</v-icon>
                  <strong>Pengajuan FPB</strong>
                </v-list-item-title>
              </v-list-item-content>
              <div
                style="font-size: 12px; margin-right: 20px"
                v-if="getFpbApprovalNeeds.result.length > 0"
              >
                <v-badge
                  color="red"
                  small
                  :content="getFpbApprovalNeeds.totalData"
                >
                </v-badge>
              </div>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-col>

      <v-col class="notification-tabs-bar2" cols="12">
        <v-tabs
          background-color="cyan"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          v-model="selectedItem"
        >
          <!-- BAR NOTIFIKASI -->
          <v-tabs-slider color="#8A8BD4"></v-tabs-slider>
          <v-tab>
            <div style="font-size: 14px; position: relative">
              <strong
                style="
                  padding: 8px 16px;
                  color: #fff;
                  display: inline-block;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                "
                >Notifikasi</strong
              >
            </div>
            <div
              style="font-size: 12px; margin-right: 20px"
              v-if="getNotification.totalUnread > 0"
            >
              <v-badge color="red" small :content="getNotification.totalUnread">
              </v-badge>
            </div>
          </v-tab>
          <v-tab>
            <div style="font-size: 14px; position: relative">
              <strong
                style="
                  padding: 8px 16px;
                  color: #fff;
                  display: inline-block;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                "
                >Pengajuan Izin</strong
              >
            </div>
            <div
              style="font-size: 12px; margin-right: 20px"
              v-if="getPermissionApprovalNeeds.result.length > 0"
            >
              <v-badge
                color="red"
                small
                :content="getPermissionApprovalNeeds.totalData"
              >
              </v-badge>
            </div>
          </v-tab>
          <v-tab>
            <div style="font-size: 14px; position: relative">
              <strong
                style="
                  padding: 8px 16px;
                  color: #fff;
                  display: inline-block;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                "
                >Pengajuan Cuti</strong
              >
            </div>
            <div
              style="font-size: 12px; margin-right: 20px"
              v-if="getLeaveApprovalNeeds.result.length > 0"
            >
              <v-badge
                color="red"
                small
                :content="getLeaveApprovalNeeds.totalData"
              >
              </v-badge>
            </div>
          </v-tab>
          <v-tab>
            <div style="font-size: 14px; position: relative">
              <strong
                style="
                  padding: 8px 16px;
                  color: #fff;
                  display: inline-block;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                "
                >Pengajuan FPB</strong
              >
            </div>
            <div
              style="font-size: 12px; margin-right: 20px"
              v-if="getFpbApprovalNeeds.result.length > 0"
            >
              <v-badge
                color="red"
                small
                :content="getFpbApprovalNeeds.totalData"
              >
              </v-badge>
            </div>
          </v-tab>
        </v-tabs>
      </v-col>

      <!--  CONTENT -->
      <v-col class="notification-content" cols="12" sm="8">
        <div class="background">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
          <div class="circle circle-3"></div>
          <div class="circle circle-4"></div>
        </div>

        <div>
          <div class="header">
            <div class="search-box">
              <!-- :class="{ active: showSearch }" -->
              <i class="fas fa-search">
                <!--  @click="toggleSearch" -->
              </i>
              <input
                type="text"
                v-model="searchKeyword"
                @keyup="searchData"
                placeholder="Mencari..."
              />
            </div>
            <div
              class="menu-icon"
              :class="{ active: isActive }"
              @click="toggleMenu"
            >
              <span class="filter-text">FILTER</span>
              <div class="hamburger" :class="{ move: isActive }">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
              </div>
            </div>

            <!-- TABEL UNTUK MENU -->
            <div :class="{ 'filter-menu': true, active: showFilters }">
              <!-- Opsi untuk memilih kategori filter -->
              <div class="kategoriFilter">
                <div class="filter-option" @click="toggleFilter('status')">
                  Status
                </div>
                <div class="filter-option" @click="toggleFilter('category')">
                  Kategori
                </div>
              </div>
              <!-- Filter Status -->
              <div v-if="showStatusFilters">
                <div
                  class="filter-item"
                  v-for="filter in [
                    'All',
                    'Draft',
                    'Waiting Approval',
                    'Canceled',
                    'Rejected',
                    'Approved'
                  ]"
                  :key="filter"
                >
                  <input
                    type="radio"
                    name="Opsi Filter"
                    :id="filter"
                    :value="filter"
                    @click="applyFilter"
                  />
                  <label :for="filter">{{ filter }}</label>
                </div>
              </div>
              <!-- Filter Kategori -->
              <div v-if="showCategoryFilters">
                <div
                  class="filter-item"
                  v-for="filter in ['Leave', 'Permission', 'eCatalogue']"
                  :key="filter"
                >
                  <input
                    type="radio"
                    name="Opsi Filter"
                    :id="filter"
                    :value="filter"
                    @click="applyCategoryFilter"
                  />
                  <label :for="filter">{{ filter }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Ini Konten Semua -->
        <div
          v-if="selectedItem === 0"
          style="height: 90%; overflow: auto; padding: 5px 35px"
        >
          <div v-if="!getNotification.loading" style="height: 100%">
            <div v-if="getNotification.result.length > 0">
              <v-hover
                v-for="(item, index) in getNotification.result"
                :key="index"
                v-slot="{ hover }"
              >
                <div>
                  <!-- Keseluruhan Konten -->
                  <v-card
                    @click="openNotification(item)"
                    :class="{
                      'on-hover': hover
                    }"
                    :elevation="hover ? 2 : 0"
                    outlined
                    :style="`margin-top:15px;  border-radius: 15px; background:#00FF95${
                      item.status === 1 ? 'white' : '#f8ffe5'
                    }; cursor:pointer; position:relative;overflow:hidden;`"
                    ref="card"
                    @touchstart="handleTouchStart"
                    @touchmove="handleTouchMove"
                  >
                    <!-- BORDER NOTIFIKASI -->
                    <v-list-item
                      three-line
                      :class="getListItemClass(item.module, item.status)"
                    >
                      <v-list-item-avatar
                        :style="`
                          width: 60px;
                          height: 60px;
                          border-radius: 50%;
                          background: ${
                            item.module === 'leave'
                              ? 'rgb(218, 218, 102)'
                              : item.module === 'permission'
                              ? 'rgb(94, 94, 213)'
                              : item.module === 'eCatalogue'
                              ? 'rgb(60, 194, 132)'
                              : '#6e6e6e'
                          };
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          border: 2px solid #3d3d3d;
                          box-shadow: 0 4px 10px rgba(108, 92, 231, 0.3),
                            0 0 20px rgba(108, 92, 231, 0.2);
                          overflow: hidden;`"
                      >
                        <span
                          style="
                            font-weight: bold;
                            color: #fff;
                            font-size: 20px;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
                            transform: rotate(-15deg);
                          "
                        >
                          {{ itemModule(item.module) }}</span
                        >
                      </v-list-item-avatar>

                      <v-list-item-content
                        :class="{
                          'card-read': item.status === 1,
                          'card-unread': item.status !== 1
                        }"
                      >
                        <!-- BESAR TEXT CONTENT -->
                        <v-list-item-title
                          class="text-title mb-4"
                          style="white-space: pre-wrap; color: #3d3d3d"
                        >
                          <span>{{ item.subject }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="mb-2" style="height: 50px">
                          {{ item.body }}
                        </v-list-item-subtitle>
                        <!-- <v-list-item-subtitle class="mb-2">
                          {{ item.document_no || '-' }}
                        </v-list-item-subtitle> -->
                        <v-list-item-subtitle
                          class="d-flex justify-between align-center"
                          :style="`color: ${statusColor(
                            item.document_status,
                            item.module
                          )};`"
                        >
                          <span
                            style="
                              background-color: rgb(243, 255, 173);
                              padding: 8px 10px;
                              border-radius: 5px;
                              margin-right: 10px;
                            "
                          >
                            Status:
                            {{
                              status(
                                item.document_status,
                                item.module
                              ).toUpperCase()
                            }}
                          </span>

                          <span
                            style="
                              margin-left: auto;
                              font-size: 10.5px;
                              color: black;
                            "
                            >{{
                              item.created_at.slice(
                                0,
                                item.created_at.lastIndexOf(' ')
                              )
                            }}</span
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-card-actions>
                        <v-btn
                          class="delete-button"
                          @click.stop="deletePerItem(item)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-list-item>

                    <!-- ======================================  -->
                    <!-- ====================================== -->

                    <div
                      :style="`position:absolute; top:18px; right:-18px; width:100px; height:15px; background:${
                        item.status === 1 ? '#83c5be' : 'red'
                      }; transform:`"
                    >
                      <p
                        style="
                          margin: 0;
                          padding: 0;
                          font-size: 10px;
                          text-align: center;
                          font-weight: 600;
                          color: white;
                        "
                      >
                        {{ item.status === 1 ? 'READ' : 'UNREAD' }}
                      </p>
                    </div>
                  </v-card>
                </div>
              </v-hover>
            </div>
            <div v-else>
              <div
                class="d-flex justify-center align-center"
                style="height: 100%"
              >
                <p
                  style="
                    color: RGBA(0, 0, 0, 0.5);
                    font-size: 18px;
                    font-weight: bold;
                    margin: auto;
                    text-align: center;
                  "
                >
                  OPPS, DATA NOT FOUND...
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </div>
        <div
          v-if="selectedItem === 1"
          style="
            padding: 10px;
            height: 100%;
            overflow: auto;
            padding: 15px 40px;
          "
        >
          <div v-if="!getPermissionApprovalNeeds.loading" style="height: 100%">
            <div v-if="getPermissionApprovalNeeds.result.length > 0">
              <v-hover
                v-for="(item, index) in getPermissionApprovalNeeds.result"
                :key="index"
                v-slot="{ hover }"
              >
                <!-- Pengajuan Izin / PM -->
                <v-card
                  @click="openNotification2(item, 'permission')"
                  :class="{ 'on-hover': hover }"
                  :elevation="hover ? 2 : 0"
                  outlined
                  :style="`margin-top:15px;  border-radius: 15px; cursor:pointer; position:relative; overflow:hidden;`"
                >
                  <v-list-item three-line class="blue-background">
                    <v-list-item-avatar
                      style="
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: rgb(94, 94, 213);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid #3d3d3d;
                        box-shadow: 0 4px 10px rgba(108, 92, 231, 0.3),
                          0 0 20px rgba(108, 92, 231, 0.2);
                        overflow: hidden;
                      "
                    >
                      <span
                        style="
                          font-weight: bold;
                          color: #fff;
                          font-size: 20px;
                          text-transform: uppercase;
                          letter-spacing: 2px;
                          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
                          transform: rotate(-15deg);
                        "
                      >
                        PM</span
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 mb-3">
                        <span>{{ item.employee.name }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="mb-2"
                        style="font-weight: bold"
                      >
                        {{ item.request_no }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="d-flex justify-between align-center"
                        :style="`color: ${statusColor(
                          item.status_permission,
                          'permission'
                        )};`"
                      >
                        <span
                          style="
                            background-color: rgb(243, 255, 173);
                            padding: 8px 10px;
                            border-radius: 5px;
                            margin-right: 10px;
                          "
                        >
                          Status:
                          {{
                            status(
                              item.status_permission,
                              'permission'
                            ).toUpperCase()
                          }}
                        </span>
                        <span
                          style="
                            margin-left: auto;
                            font-size: 12px;
                            color: black;
                          "
                          >{{
                            item.created_at.slice(
                              0,
                              item.created_at.lastIndexOf(' ')
                            )
                          }}</span
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <div
                    :style="`position:absolute; top:18px; right:-18px; width:100px; height:15px; background:${
                      item.status_permission === 1 ? 'red' : '#83c5be'
                    }; transform: rotate(40deg);`"
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 10px;
                        text-align: center;
                        font-weight: 600;
                        color: white;
                      "
                    >
                      {{
                        item.status_permission === 1
                          ? 'WAITING'
                          : status(
                              item.status_permission,
                              'permission'
                            ).toUpperCase()
                      }}
                    </p>
                  </div>
                </v-card>
              </v-hover>
            </div>
            <div
              v-else
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <p
                style="
                  color: RGBA(0, 0, 0, 0.5);
                  font-size: 18px;
                  font-weight: bold;
                  margin: auto;
                  text-align: center;
                "
              >
                OPPS, DATA NOT FOUND...
              </p>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 100%"
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
        <div
          v-if="selectedItem === 2"
          style="
            padding: 10px;
            height: 100%;
            overflow: auto;
            padding: 15px 40px;
          "
        >
          <div v-if="!getLeaveApprovalNeeds.loading" style="height: 100%">
            <div v-if="getLeaveApprovalNeeds.result.length > 0">
              <v-hover
                v-for="(item, index) in getLeaveApprovalNeeds.result"
                :key="index"
                v-slot="{ hover }"
              >
                <!-- Pengajuan Cuti / LV -->
                <v-card
                  @click="openNotification2(item, 'leave')"
                  :class="{ 'on-hover': hover }"
                  :elevation="hover ? 2 : 0"
                  outlined
                  :style="`margin-top:15px;  border-radius: 15px; cursor:pointer; position:relative;overflow:hidden;`"
                >
                  <v-list-item three-line class="yellow-background">
                    <v-list-item-avatar
                      style="
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: rgb(218, 218, 102);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid #3d3d3d;
                        box-shadow: 0 4px 10px rgba(108, 92, 231, 0.3),
                          0 0 20px rgba(108, 92, 231, 0.2);
                        overflow: hidden;
                      "
                    >
                      <span
                        style="
                          font-weight: bold;
                          color: #fff;
                          font-size: 20px;
                          text-transform: uppercase;
                          letter-spacing: 2px;
                          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
                          transform: rotate(-15deg);
                        "
                      >
                        LV</span
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 mb-3">
                        <span>{{ item.employee.name }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="mb-2"
                        style="font-weight: bold"
                      >
                        {{ item.request_no }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="d-flex justify-between align-center"
                        :style="`color: ${statusColor(
                          item.status_leave,
                          'leave'
                        )};`"
                      >
                        <span
                          style="
                            background-color: rgb(243, 255, 173);
                            padding: 8px 10px;
                            border-radius: 5px;
                            margin-right: 10px;
                          "
                        >
                          Status:
                          {{ status(item.status_leave, 'leave').toUpperCase() }}
                        </span>
                        <span
                          style="
                            margin-left: auto;
                            font-size: 12px;
                            color: black;
                          "
                          >{{
                            item.created_at.slice(
                              0,
                              item.created_at.lastIndexOf(' ')
                            )
                          }}</span
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <div
                    :style="`position:absolute; top:18px; right:-18px; width:100px; height:15px; background:${
                      item.status_leave === 1 ? 'red' : '#83c5be'
                    }; transform: rotate(40deg);`"
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 10px;
                        text-align: center;
                        font-weight: 600;
                        color: white;
                      "
                    >
                      {{
                        item.status_leave === 1
                          ? 'WAITING'
                          : status(
                              item.status_leave,
                              'permission'
                            ).toUpperCase()
                      }}
                    </p>
                  </div>
                </v-card>
              </v-hover>
            </div>
            <div
              v-else
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <p
                style="
                  color: RGBA(0, 0, 0, 0.5);
                  font-size: 18px;
                  font-weight: bold;
                  margin: auto;
                  text-align: center;
                "
              >
                OPPS, DATA NOT FOUND...
              </p>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 100%"
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
        <div
          v-if="selectedItem === 3"
          style="
            padding: 10px;
            height: 100%;
            overflow: auto;
            padding: 15px 40px;
          "
        >
          <div v-if="!getFpbApprovalNeeds.loading" style="height: 100%">
            <div v-if="getFpbApprovalNeeds.result.length > 0">
              <v-hover
                v-for="(item, index) in getFpbApprovalNeeds.result"
                :key="index"
                v-slot="{ hover }"
              >
                <!-- Pengajuan Cuti / LV -->
                <v-card
                  @click="openNotification2(item, 'eCatalogue')"
                  :class="{ 'on-hover': hover }"
                  :elevation="hover ? 2 : 0"
                  outlined
                  :style="`margin-top:15px;  border-radius: 15px; cursor:pointer; position:relative;overflow:hidden;`"
                >
                  <v-list-item three-line class="green-background">
                    <v-list-item-avatar
                      style="
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: rgb(60, 194, 132);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid #3d3d3d;
                        box-shadow: 0 4px 10px rgba(108, 92, 231, 0.3),
                          0 0 20px rgba(108, 92, 231, 0.2);
                        overflow: hidden;
                      "
                    >
                      <span
                        style="
                          font-weight: bold;
                          color: #fff;
                          font-size: 20px;
                          text-transform: uppercase;
                          letter-spacing: 2px;
                          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
                          transform: rotate(-15deg);
                        "
                      >
                        CAT</span
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 mb-3">
                        <!-- <span>{{ item.employee.name }}</span> -->
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="mb-2"
                        style="font-weight: bold"
                      >
                        <!-- {{ item.request_no }} -->
                      </v-list-item-subtitle>
                      <!-- <v-list-item-subtitle
                        class="d-flex justify-between align-center"
                        :style="`color: ${statusColor(
                          item.status_leave,
                          'eCatalogue'
                        )};`"
                      >
                        <span
                          style="
                            background-color: rgb(243, 255, 173);
                            padding: 8px 10px;
                            border-radius: 5px;
                            margin-right: 10px;
                          "
                        >
                          Status:
                          {{ status(item.status_leave, 'eCatalogue').toUpperCase() }}
                        </span>
                        <span
                          style="
                            margin-left: auto;
                            font-size: 12px;
                            color: black;
                          "
                          >{{
                            item.created_at.slice(
                              0,
                              item.created_at.lastIndexOf(' ')
                            )
                          }}</span
                        >
                      </v-list-item-subtitle> -->
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <div
                    :style="`position:absolute; top:18px; right:-18px; width:100px; height:15px; background:${
                      item.status_leave === 1 ? 'red' : '#83c5be'
                    }; transform: rotate(40deg);`"
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 10px;
                        text-align: center;
                        font-weight: 600;
                        color: white;
                      "
                    >
                      {{
                        item.status_leave === 1
                          ? 'WAITING'
                          : status(
                              item.status_leave,
                              'permission'
                            ).toUpperCase()
                      }}
                    </p>
                  </div> -->
                </v-card>
              </v-hover>
            </div>
            <div
              v-else
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <p
                style="
                  color: RGBA(0, 0, 0, 0.5);
                  font-size: 18px;
                  font-weight: bold;
                  margin: auto;
                  text-align: center;
                "
              >
                OPPS, DATA NOT FOUND...
              </p>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 100%"
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { Reveal } from 'vue-burger-menu'
import '@fortawesome/fontawesome-free/css/all.css'
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'hrdashboard',
  data: () => ({
    showFilters: false,
    showStatusFilters: false,
    showCategoryFilters: false,
    isActive: false,
    showSearch: true,
    hrsApi: buildType.apiURL('hrs'),
    esanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    actionValue: 0,
    selectedItem: 0,
    selectedData: [],
    searchKeyword: '',
    selectedFilters: []
  }),
  async mounted() {
    await this.getDataFromApi()
    // document.addEventListener('click', this.closeMenuOnClickOutside)
  },

  computed: {
    ...mapGetters([
      'getUserProfile',
      'getNotification',
      'getPermissionApprovalNeeds',
      'getLeaveApprovalNeeds',
      'getFpbApprovalNeeds'
    ])
  },

  watch: {
    async selectedItem() {
      console.log(this.selectedItem)
      if (this.selectedItem !== undefined) {
        await this.getDataFromApi()
      } else {
        this.selectedItem = 0
        await this.getDataFromApi()
      }
    },
    selectedData() {
      console.log(this.selectedData)
    }
  },
  created() {
    this.setTabs([])
  },
  methods: {
    ...mapActions([
      'loadNotification',
      'loadPermissionApprovalNeeds',
      'loadLeaveApprovalNeeds',
      'loadFpbApprovalNeeds'
    ]),
    ...mapMutations(['setTabs']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'NO'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX
      this.currentX = this.startX
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX
      const deltaX = this.currentX - this.startX
      if (deltaX < -5) {
        this.$refs.card.classList.add('show-delete-button')
      } else {
      }
    },
    handleTouchEnd() {
      this.$refs.card.classList('show-delete-button')
    },
    getListItemClass(module, status) {
      let classList = ''

      switch (module) {
        case 'leave':
          classList += 'yellow-background '
          break
        case 'permission':
          classList += 'blue-background '
          break
        case 'eCatalogue':
          classList += 'green-background '
          break
        default:
          break
      }

      if (status === 1) {
        classList += 'card-read'
      } else {
        classList += 'card-unread'
      }

      return classList.trim()
    },

    checkMove(event) {
      return event.target.classList.contains('v-card')
    },
    handleChange(event) {
      console.log('Perubahan urutan:', event)
    },

    async getDataFromApi() {
      await this.loadNotification({
        keyword: '',
        document_status: null,
        module: null
      })
      await this.loadPermissionApprovalNeeds()
      await this.loadLeaveApprovalNeeds()
      await this.loadFpbApprovalNeeds()
    },
    async searchData() {
      try {
        await this.loadNotification({
          keyword: this.searchKeyword,
          document_status: null,
          module: null
        })
      } catch (error) {
        console.error('Error searching data:', error)
      }
    },
    async applyFilter() {
      const selectedFilter = document.querySelector(
        'input[name="Opsi Filter"]:checked'
      )

      if (selectedFilter) {
        const selectedValue = selectedFilter.value

        let cekNilai
        switch (selectedValue) {
          case 'Draft':
            cekNilai = 0
            break
          case 'Waiting Approval':
            cekNilai = 1
            break
          case 'Canceled':
            cekNilai = -2
            break
          case 'Rejected':
            cekNilai = -1
            break
          case 'Approved':
            cekNilai = 2
            break
          default:
            cekNilai = null
        }

        try {
          await this.loadNotification({
            keyword: this.searchKeyword,
            document_status: cekNilai
          })
        } catch (error) {
          console.error('Error filtering data:', error)
        }
      } else {
        try {
          await this.loadNotification({
            keyword: this.searchKeyword,
            document_status: null // Assuming null represents "All" in your backend
          })
        } catch (error) {
          console.error('Error filtering data:', error)
        }
      }
    },
    async applyCategoryFilter() {
      const selectedFilter = document.querySelector(
        'input[name="Opsi Filter"]:checked'
      )
      console.log(selectedFilter)
      if (selectedFilter) {
        const selectedValue = selectedFilter.value

        console.log(selectedValue)
        let cekNilai
        switch (selectedValue) {
          case 'Leave':
            cekNilai = 'leave'
            break
          case 'Permission':
            cekNilai = 'permission'
            break
          case 'eCatalogue':
            cekNilai = 'eCatalogue'
            break
          default:
            cekNilai = null
        }

        try {
          console.log('hereeee>>>>>')
          await this.loadNotification({
            keyword: this.searchKeyword,
            module: cekNilai
          })
        } catch (error) {
          console.error('Error filtering data:', error)
        }
      } else {
        try {
          await this.loadNotification({
            keyword: this.searchKeyword,
            module: null
          })
        } catch (error) {
          console.error('Error filtering data:', error)
        }
      }
    },
    toggleMenu() {
      this.showFilters = !this.showFilters
      this.showStatusFilters = false
      this.showCategoryFilters = false
      this.isActive = !this.isActive
    },
    toggleFilter(filterType) {
      console.log(filterType)
      if (filterType === 'status') {
        this.showStatusFilters = true
        this.showCategoryFilters = false
      } else if (filterType === 'category') {
        this.showStatusFilters = false
        this.showCategoryFilters = true
      }
    },
    // closeMenuOnClickOutside(event) {
    //   try {
    //     const menuIcon = document.querySelector('.menu-icon')
    //     const menu = document.querySelector('.filter-menu')
    //     const isClickInsideMenuIcon = menuIcon.contains(event.target)
    //     const isClickInsideMenu = menu.contains(event.target)

    //     if (!isClickInsideMenu && !isClickInsideMenuIcon) {
    //       this.showFilters = false
    //       this.isActive = false
    //     }
    //   } catch (error) {
    //     console.log('notifications.vue | listener_error_line:1057>>>', error)
    //   }
    // },
    toggleSearch() {
      this.showSearch = !this.showSearch
    },
    async openNotification(pItem) {
      if (pItem.status === 0) {
        await this.changeToRead(pItem)
      }
      if (pItem.module === 'leave') {
        setTimeout(() => {
          this.$router.push(
            // `/hr/leave/detail/${pItem.document_id}?redirect_from=inappnotification`
            `/hr/leave/detail/${pItem.document_id}`
          )
        }, 100)
      }
      if (pItem.module === 'permission') {
        setTimeout(() => {
          this.$router.push(
            `/hr/permission/detail/${pItem.document_id}`
            // `/hr/permission/detail/${pItem.document_id}?redirect_from=inappnotification`
          )
        }, 100)
      }
      if (pItem.module === 'eCatalogue') {
        // this.$store.commit('setFpbId', pItem.document_id)
        setTimeout(() => {
          this.$router.push(`/e-catalogue/fpb-list/fpb/${pItem.document_id}`)
          // this.$router.push('/e-catalogue/fpb-list/fpb')
        }, 100)
      }
    },
    openNotification2(pItem, pModule) {
      if (pModule === 'leave') {
        setTimeout(() => {
          this.$router.push(`/hr/leave/detail/${pItem.id}`)
        }, 100)
      }
      if (pModule === 'permission') {
        setTimeout(() => {
          this.$router.push(`/hr/permission/detail/${pItem.id}`)
        }, 100)
      }
      if (pModule === 'eCatalogue') {
        setTimeout(() => {
          this.$router.push(`/e-catalogue/fpb-list/fpb/${pItem.id}`)
          // this.$router.push('/e-catalogue/fpb-list/fpb')
        }, 100)
      }
    },
    async changeToRead(pItem) {
      if (pItem.status === 0) {
        await axios
          .post(`${this.esanqua}notification/change_to_read`, {
            id: pItem.id
          })
          .then((res) => {
            if (res.data.status_code === '00') {
              this.loadNotification({
                keyword: this.searchKeyword,
                module: null
              })
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
            return null
          })
          .catch((err) => {
            console.log(err)
            return this.showMsgDialog('error', err, false)
          })
      }
      return null
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.selectedData.length < 2) {
            this.delete()
          } else {
            this.showMsgDialog(
              'warning',
              'Pilih salah satu untuk delete',
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = 0
            }, 200)
          }
          break
        case 2:
          this.delete()
          break
        default:
          break
      }
    },
    disableItem(item) {
      if (this.selectedData.length < 1) {
        return item.id === 1
      } else {
        return item.id === 2
      }
    },

    delete() {
      this.showMsgDialog(
        'question',
        `${
          this.actionValue === 2 ? ' Semua ' : this.selectedData.length + ' '
        }data akan dihapus secara permanent, Anda yakin ?`,
        true
      ).then((res) => {
        if (res.isConfirmed) {
          if (this.actionValue === 1) {
            this.startDelete()
          }
          if (this.actionValue === 2) {
            this.deleteAll()
          }
        } else {
          this.actionValue = 0
        }
      })
    },
    async startDelete(item) {
      await axios
        .delete(`${this.esanqua}notification/delete/${this.selectedData[0].id}`)
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.showMsgDialog('success', res.data.status_msg, false)
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog('error', err, false)
        })
      this.actionValue = 0
      this.selectedData = []
      this.getDataFromApi()
    },
    async deletePerItem(item) {
      const confirmation = await this.showMsgDialog(
        'question',
        'Data akan dihapus secara permanent, Anda yakin ?',
        true
      )

      if (confirmation.isConfirmed) {
        console.log('notif card>>>>', item)
        await axios
          .delete(`${this.esanqua}notification/delete/${item.id}`)
          .then((res) => {
            console.log(res)
            if (res.data.status_code === '00') {
              this.showMsgDialog('success', res.data.status_msg, false)
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          })
          .catch((err) => {
            console.log(err)
            this.showMsgDialog('error', err, false)
          })
        this.actionValue = 0
        this.selectedData = []
        this.getDataFromApi()
      }
    },

    async deleteAll() {
      await axios
        .delete(`${this.esanqua}notification/delete_all`)
        .then((res) => {
          console.log(res)
          this.actionValue = 0
          this.selectedData = []
          this.showMsgDialog('success', res.data.status_msg, 'false')
          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    itemModule(str) {
      switch (str) {
        case 'leave':
          return 'LV'
        case 'permission':
          return 'PM'

        case 'eCatalogue':
          return 'CAT'
        default:
          return '-'
      }
    },
    status(val, mod) {
      if (mod === 'leave') {
        switch (val) {
          case -2:
            return 'Canceled'
          case -1:
            return 'Rejected'
          case 0:
            return 'Draft'
          case 1:
            return 'Waiting Approval'
          case 2:
            return 'Waiting Approval'
          case 3:
            return 'Approved'
        }
      }
      if (mod === 'permission') {
        switch (val) {
          case -2:
            return 'Canceled'
          case -1:
            return 'Rejected'
          case 0:
            return 'Draft'
          case 1:
            return 'Waiting Approval'
          case 2:
            return 'Approved'
        }
      }
      if (mod === 'eCatalogue') {
        switch (val) {
          case -1:
            return 'Rejected'
          case 0:
            return 'Draft'
          case 1:
            return 'Waiting Approval'
          case 2:
            return 'In Progress'
          case 3:
            return 'Close'
          case 4:
            return 'Cancel'
        }
      }
      return '-'
    },
    statusColor(val, mod) {
      if (mod === 'permission') {
        switch (val) {
          case -2:
            return 'red'

          case -1:
            return 'red'
          case 0:
            return '#05668d'
          case 1:
            return '#05668d'
          case 2:
            return 'rgba(0, 223, 7, 255)'
        }
      }
      if (mod === 'leave') {
        switch (val) {
          case -2:
            return 'red'

          case -1:
            return 'red'
          case 0:
            return '#05668d'
          case 1:
            return '#05668d'
          case 2:
            return '#05668d'
          case 3:
            return 'rgba(0, 223, 7, 255)'
        }
      }
      if (mod === 'eCatalogue') {
        switch (val) {
          case -2:
            return 'red'

          case -1:
            return 'red'
          case 0:
            return '#05668d'
          case 1:
            return '#05668d'
          case 2:
            return '#05668d'
          case 3:
            return 'rgba(0, 223, 7, 255)'
        }
      }
      return 'black'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 10px 40px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-box {
  position: relative;
  width: 210px;
}

.search-box .fas.fa-search {
  // background: #000ed7;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid white;
  padding: 5px;
}
.search-box input[type='text'] {
  width: calc(100% - 20px);
  height: 50px;
  padding: 8px;
  padding-left: 60px;
  border: 2px solid #ccc;
  border-radius: 40px;
  box-sizing: border-box;
  transition: width 0.3s ease, border-color 0.3s ease;
}

.fas.fa-search {
  color: #c5c4c3;
  cursor: pointer;
}

.search-box input {
  width: calc(100% - 20px);
  height: 50px;
  padding: 8px;
  padding-left: 30px;
  border: 3px solid #ccc;
  font-size: 14px;
  // font-weight: bold;
  color: #333;
  border-radius: 40px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.search-box input:focus {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  outline: none;
  border-color: #85b7d9;
}

.search-box i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.search-box.active input[type='text'] {
  width: calc(100% - 120px);
}

.menu-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 40px;
  transition: background-color 0.3s ease;
}

.menu-icon:hover {
  background-color: #f0f0f0;
}

.menu-icon.active {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.filter-text {
  margin-right: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #6e6e6e;
}

.hamburger {
  background: #6e6e6e;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 50%;
  padding: 5px;
  transition: transform 0.3s ease;
}

.hamburger.active {
  transform: rotate(90deg);
  color: #6c5ce7;
}

/* HAMBURGER BAR ROTASI */

.hamburger.move .bar:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.hamburger.move .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.move .bar:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

.hamburger .bar {
  transition: transform 0.5s ease, opacity 0.3s ease;
}

.bar {
  width: 50%;
  height: 4px;
  background-color: #fff;
  border-radius: 5px;
}

.filter-menu {
  display: none;
  background: linear-gradient(to bottom right, #edeee4, #ffffff);
  border: 2px solid #6b6b6b;
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  top: 70px;
  right: 40px;
  width: 220px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.filter-menu:hover {
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.3);
}

.filter-menu.active {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.filter-item label:hover {
  color: #636956;
  cursor: pointer;
}

.filter-item input[type='checkbox'] {
  margin-right: 10px;
}

.filter-item label {
  margin-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #030000;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  pointer-events: auto;
}

.filter-item input[type='checkbox']:checked + label:before {
  content: '\2713';
  display: inline-block;
  font-size: 16px;
  margin-right: 5px;
}

.filter-item input[type='checkbox']:checked + label {
  font-weight: bold;
}

.filter-option {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  padding: 10px 20px;
  margin-bottom: 15px;
  border: none;
  border-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #6b6b6b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
}

.filter-option:hover {
  transform: translateY(-2px);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
}

.card-read {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 40;
}
.card-unread {
  background-color: #e5e4e2;
  font-weight: 600;
}
.yellow-background {
  border-left: 5px solid rgb(218, 218, 102);
  padding-left: 15px;
}

.blue-background {
  border-left: 5px solid rgb(94, 94, 213);
  padding-left: 15px;
}

.green-background {
  border-left: 5px solid rgb(60, 194, 132);
  padding-left: 15px;
}

.delete-button {
  transition: transform 0.3s ease;
  transform: translateX(85%);
}

.v-card:hover .delete-button {
  transform: translateX(0%);
}

#clock {
  font-family: 'Share Tech Mono', monospace;
  text-align: center;
  color: #525252;
  font-weight: bold;
  text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
  .time {
    letter-spacing: 0.05em;
    font-size: 80px;
    padding: 5px 0;
    margin: 0;
  }
  .date {
    letter-spacing: 0.1em;
    font-size: 24px;
    margin: 0;
  }
}
.v-card.on-hover {
  background-color: rgba(#fff, 0.8);
  .v-card__text {
    color: rgb(238, 20, 20);
  }
}
.notification-content {
  height: 100%;
}
.notification-tabs-bar2 {
  display: none;
}
.notification-tabs-bar {
  border-radius: 15px;
  display: inline-block;
}

.v-list-item {
  border-radius: 20px;
  overflow: hidden;
}

.v-list-item.v-list-item--active {
  background-color: #e0f0ff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  position: relative;
}

.v-list-item:not(.v-list-item--active) .v-icon {
  color: #999;
}
.v-list-item.v-list-item--active .v-icon {
  color: black;
}
@media (max-width: 600px) {
  .notification-tabs-bar {
    display: none;
  }
  .notification-tabs-bar2 {
    margin-top: 10px;
    display: flex;
    height: 50px;
  }
  .notification-content {
    height: 100%;
  }
}
</style>
